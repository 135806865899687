import { z } from 'zod'
import { recaptcha } from '../../formats.js'
import { defaultErrorResponses, defaultResponse } from '../../response.js'
import type { Schema } from '../../types.js'

// example schema
export const schema = {
	'/dashboard/user/avatar/sign-url': {
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Get user avatar sign url',
			headers: recaptcha,
			body: z.object({
				content_type: z.enum(['image/jpeg', 'image/jpg', 'image/png']),
			}),
			response: {
				200: defaultResponse.extend({
					data: z.object({
						file_name: z.string().min(1),
						url: z.string().min(1),
					}),
				}),
				...defaultErrorResponses,
			},
		},
	},
	'/dashboard/user/avatar/update': {
		post: {
			tags: ['Dashboard'],
			security: [{ bearerAuth: [] }],
			summary: 'Update user avatar',
			headers: recaptcha,
			body: z.object({
				file_name: z.string().min(1).trim(),
			}),
			response: {
				201: defaultResponse.extend({
					data: z.literal('Your avatar was updated'),
				}),
				...defaultErrorResponses,
			},
		},
	},
} as const satisfies Schema
