import { defineStore } from "pinia";
import { z } from "zod";
import { useLocalStorage } from "@vueuse/core";
import { type Ref } from "vue";

import { schema } from "~/.nuxt/contracts/src/routes/dashboard/me";
import { schema as userSchema } from "~/.nuxt/contracts/src/routes/dashboard/user";

const getMeResponseSchema =
  schema["/dashboard/me"].get.response[200].shape.data;
const getMeResponseSchemaPartial = getMeResponseSchema.deepPartial();
const getAvatarSignedUrlSchema =
  userSchema["/dashboard/user/avatar/sign-url"].post.response[200].shape.data;
const postAvatarSignedUrlSchema =
  userSchema["/dashboard/user/avatar/sign-url"].post.body;

export const useUserStore = defineStore("user", {
  state: (): {
    me: Ref<z.infer<typeof getMeResponseSchemaPartial>>;
    loading: boolean;
    signedUrlResponse: z.infer<typeof getAvatarSignedUrlSchema>;
    theme: string;
  } => ({
    me: useLocalStorage("pinia/dashboard/me", {}),
    loading: false,
    signedUrlResponse: {
      file_name: "",
      url: "",
    },
    theme: "light",
  }),
  getters: {
    isPayee(state) {
      return state.me.user?.type === "payee";
    },
    hasCompany(state) {
      return !!state.me.company;
    },
  },
  actions: {
    async getMe() {
      const response = await this.$client["/dashboard/me"].get();
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      const responseJson = await response.json();
      this.me = getMeResponseSchema.parse(responseJson.data);
    },
    async getSignedUrl(body: z.infer<typeof postAvatarSignedUrlSchema>) {
      const response = await this.$client[
        "/dashboard/user/avatar/sign-url"
      ].post({
        json: body,
      });

      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const responseJson = await response.json();

      this.signedUrlResponse = getAvatarSignedUrlSchema.parse(
        responseJson.data
      );
    },
    async avatarUpdate(fileName: string) {
      const response = await this.$client["/dashboard/user/avatar/update"].post(
        {
          json: {
            file_name: fileName,
          },
        }
      );

      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
    async sendSession() {
      const sessionStore = useSessionStore();
      if (sessionStore.sessionId.length <= 0) return;

      const response = await this.$client["/dashboard/user_sessions"].post({
        json: {
          clerk_session_id: sessionStore.sessionId,
        },
      });
      if (!response.ok) {
        throw new Error(response.statusText);
      }
    },
  },
});
